import { Component } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { Platform } from '@ionic/angular';
//import { AlertController } from 'ionic-angular';
const { SplashScreen } = Plugins;
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: [
    './side-menu/styles/side-menu.scss',
    './side-menu/styles/side-menu.shell.scss',
    './side-menu/styles/side-menu.responsive.scss'
  ]
})
export class AppComponent {
  appPages = [
    {
      title: 'เมนูหลัก',
      url: '/app/categories',
      icon: './assets/sample-icons/tabs/home.svg'
    },
    {
      title: 'คณะกรรมการ 1935',
      url: '/app/firebase/listing/5',
      icon: './assets/images/group.svg'
    },
    {
      title: 'คณะกรรมการ 30',
      url: '/app/firebase/listing/6',
      icon: './assets/images/001aaabb.svg'
    },
    {
      title: 'คณะกรรมการ 76',
      url: '/app/firebase/listing/7',
      icon: './assets/images/002-11.svg'
    },
    {
      title: 'คณะกรรมการ 26',
      url: '/app/firebase/listing/8',
      icon: './assets/images/003-22.svg'
    },
    {
      title: 'คณะกรรมการ 35',
      url: '/app/firebase/listing/9',
      icon: './assets/images/004.svg'
    },
    {
      title: 'เพื่อนผู้จากไป',
      url: '/app/firebase/listing/100',
      icon: './assets/images/funeral.svg'
    },
    {
      title: 'วันเกิดเพื่อน (60วัน)',
      url: '/app/firebase/listing/555',
      icon: './assets/images/gift.svg'
    },
    {
      title: 'ธุรกิจของเพื่อนๆ',
      url: '/app/categories/food',
      icon: './assets/images/shop.svg'
    },
    {
      title: 'ที่ตั้งธุรกิจของเพื่อนๆ',
      url: '/maps',
      icon: './assets/images/map.svg'
    },
    {
      title: 'รางวัลเกียรติยศ',
      url: '/app/firebase/listing/666',
      icon: './assets/images/medal-reward-svgrepo-com.svg'
    },

    /*
   {
      title: 'Profile',
      url: '/app/user',
      icon: './asse*/
    {
      title: 'เกี่ยวกับเรา',
      url: '/contact-card',
      icon: './assets/sample-icons/side-menu/contact-card.svg'
    } /*,
    {
      title: 'ภาษา/language',
      url: '/contact-card',
      icon: './assets/images/outline-language-24px.svg' 
    }*/
  ];
  accountPages = [
    {
      title: 'Log In',
      url: '/auth/login',
      icon: './assets/sample-icons/side-menu/login.svg'
    },
    {
      title: 'Sign Up',
      url: '/auth/signup',
      icon: './assets/sample-icons/side-menu/signup.svg'
    },
    {
      title: 'Tutorial',
      url: '/walkthrough',
      icon: './assets/sample-icons/side-menu/tutorial.svg'
    },
    {
      title: 'Getting Started',
      url: '/getting-started',
      icon: './assets/sample-icons/side-menu/getting-started.svg'
    },
    {
      title: '404 page',
      url: '/page-not-found',
      icon: './assets/sample-icons/side-menu/warning.svg'
    }
  ];

  textDir = 'ltr';

  constructor(public translate: TranslateService, public platform: Platform) {
    this.initializeApp();
    this.setLanguage();

  }

  async initializeApp() {
    try {
      await SplashScreen.hide();
    } catch (err) {
      console.log('This is normal in a browser', err);
    }
  }
  closeApp() {
    //alert('Close app?');
    navigator['app'].exitApp();
  }

  setLanguage() {
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('en');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use('en');

    // this is to determine the text direction depending on the selected language
    // for the purpose of this example we determine that only arabic and hebrew are RTL.
    // this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
    //   this.textDir = (event.lang === 'ar' || event.lang === 'iw') ? 'rtl' : 'ltr';
    // });
  }

}
